import React from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
// ---------------------------------------------
import Layout from "../components/layout"
import RichText from "../components/richtext"
import Revealer from "../components/revealer"
import SEO from "../components/seo"
// ---------------------------------------------

const ArticlePage = ({ data }) => {

  const {
    title,
    body,
    backgroundColour,
    description,
    coverImage
  } = data.contentfulArticle

  const shareImage = {
    fluid: coverImage.thumbnail
  }

  return (
    <Layout footerColour="#FFFFFF">
      <SEO title={title} description={description.description} shareImage={shareImage} />
      <Revealer>
        <section className="has-links" style={backgroundColour && {
          backgroundColor: backgroundColour
        }}>
          {body && (
            <article>
              <p>
                <Link to="/journal"><em>{`<< back to The Journal`}</em></Link>
              </p>
              <p>
                <strong>{title}{" "}</strong>
              </p>
              <RichText content={body} debug={title} />
              <p>
                <Link to="/journal"><em>{`<< back to The Journal`}</em></Link>
              </p>
            </article>
          )}
        </section>
      </Revealer>
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageQuery($slug: String!){
    contentfulArticle(slug: {eq: $slug}) {
      ...Article
      backgroundColour
    }
  }
`

export const articleFragment = graphql`

	fragment RichTextEmbeddedAsset on ContentfulAsset {
		__typename
		contentful_id
		title
		fluid(maxWidth: 960) {
			src
			aspectRatio
			sizes
			srcSet
		}
	}

  fragment Article on ContentfulArticle {
    title
    slug
    publishedOn: publishDate(formatString: "MMMM Do YYYY")
    description {
      description
      childMarkdownRemark {
        html
      }
    }
    coverImage {
      thumbnail: fluid(maxWidth: 500, quality: 90) {
        src
        aspectRatio
        sizes
        srcSet
      }
      fullscreen: fluid(maxWidth: 1800, quality: 90) {
        src
        aspectRatio
        sizes
        srcSet
      }
    }
    body {
			raw
			references {
				... on ContentfulAsset {
					...RichTextEmbeddedAsset
				}
			}
    }
    backgroundColour
  }
`
